import React, { useState, useEffect, useCallback, useRef } from "react";
import { ResourceInputItem, ResourceItem } from ".";
import { motion } from "framer-motion";
import { fs } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEqual } from "lodash";
import update from 'immutability-helper';

const InfoWell = () => {
  return (
    <div className="info-well">
      <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 8C11.4477 8 11 8.44771 11 9V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V9C13 8.44772 12.5523 8 12 8Z"
            fill="var(--md_gray)"
          />
          <path
            d="M12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14Z"
            fill="var(--md_gray)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 12C20 16.4183 16.4183 20 12 20C7.58173 20 4 16.4183 4 12C4 7.58172 7.58173 4 12 4C16.4183 4 20 7.58172 20 12ZM18 12C18 15.3137 15.3137 18 12 18C8.68628 18 6 15.3137 6 12C6 8.68629 8.68628 6 12 6C15.3137 6 18 8.68629 18 12Z"
            fill="var(--md_gray)"
          />
        </svg>
      </div>

      <p className="xs c-md_gray">Resources are non-authoritative and should not be used in place of the standard.</p>
    </div>
  );
};

const getSpecificSectionNames = (sections) => {
  const clonedList = { ...sections };

  Object.keys(clonedList).forEach((key) => {
    clonedList[key] = [...clonedList[key].map((el) => true)];
  });

  return clonedList;
};

const Resources = ({ resourceList, setBoardResources, setShowVideo }) => {
  const [generalExpanded, setGeneralExpanded] = useState(false);
  const [specificExpanded, setSpecificExpanded] = useState(true);


  const [isEditingGeneralResources, setIsEditingGeneralResources] = useState(false);
  const [isEditingSpecificResources, setIsEditingSpecificResources] = useState(false);

  const [resources, setResources] = useState();
  const [generalResourceList, setGeneralResourceList] = useState();
  const [generalEditResourceList, setGeneralEditResourceList] = useState();
  const [specificResourceList, setSpecificResourceList] = useState();
  const [specificEditResourceList, setSpecificEditResourceList] = useState();

  const [validateResources, setValidateResources] = useState({
    general: [],
    specific: resourceList && resourceList.specific ? getSpecificSectionNames(resourceList.specific): {},
  });

  const [isPublishing, setIsPublishing] = useState(false);

  const currentUser = useStoreState((state) => state.user);
  const currentBoard = useStoreState((state) => state.session.currentBoard);
  const currentSectionId = useStoreState((state) => state.session.currentSectionId);

  const stateIsEditGeneralRef = useRef();
  const stateIsEditSpecificRef = useRef();
  const stateGenEditRes = useRef();
  const stateSpeEditRes = useRef();
  const stateValidateRes = useRef();
  stateIsEditGeneralRef.current = isEditingGeneralResources;
  stateIsEditSpecificRef.current = isEditingSpecificResources;
  const stateSectionId = useRef();

  useEffect(() => {
    stateValidateRes.current = {
      general: [],
      specific: resourceList && resourceList.specific ? getSpecificSectionNames(resourceList.specific): {},
    };
    stateSectionId.current = currentSectionId;
    if (resourceList && resourceList.general && resourceList.general.length > 0){
      setGeneralResourceList(resourceList.general);
    }
    else{
      setGeneralResourceList([]);
    }

    if (resourceList && resourceList.specific && (Object.entries(resourceList.specific).length === 0 || !resourceList.specific[currentSectionId])) {
      resourceList.specific[currentSectionId] = [];
    }
    if(stateIsEditSpecificRef.current === true){ 
      setIsEditingSpecificResources(false);
      stateIsEditSpecificRef.current = false;
      setSpecificEditResourceList([]);
      stateSpeEditRes.current = [];
      if(isPublishing === true){
        setIsPublishing(false);
      }
    }

    if (resourceList && resourceList.specific && Object.entries(resourceList.specific).length > 0){
      setSpecificResourceList(resourceList.specific[currentSectionId]);
    }
    else{ 
      setSpecificResourceList([]);
    }


    setResources(resourceList);
    setValidateResources({
      general: resourceList && resourceList.general ? resourceList.general.map(() => true): [],
      specific: resourceList && resourceList.specific ? getSpecificSectionNames(resourceList.specific) : {},
    });
    stateValidateRes.current = {
      general: resourceList && resourceList.general ? resourceList.general.map(() => true): [],
      specific: resourceList && resourceList.specific ? getSpecificSectionNames(resourceList.specific) : {},
    };
  }, [currentSectionId, resourceList]);

  const updateValidationItem = useCallback(
    (resourceId, value) => {
      const clonedList = stateIsEditGeneralRef.current ? stateGenEditRes.current : stateSpeEditRes.current;

      if(clonedList !== undefined){
        const idx = clonedList.findIndex((item) => item.resourceId === resourceId);

        const clonedValidateResourceList = stateIsEditGeneralRef.current
          ? [...stateValidateRes.current.general]
          : [...stateValidateRes.current.specific[stateSectionId.current]];
        clonedValidateResourceList[idx] = value;
        const updatedValidateResourceList = stateIsEditGeneralRef.current
          ? { general: clonedValidateResourceList, specific: { ...stateValidateRes.current.specific } }
          : {
              general: [...stateValidateRes.current.general],
              specific: { ...stateValidateRes.current.specific, [stateSectionId.current]: clonedValidateResourceList },
            };
  
        if (!isEqual(stateValidateRes.current, updatedValidateResourceList)) {
          setValidateResources(updatedValidateResourceList);
          stateValidateRes.current = updatedValidateResourceList;
        }
      }
    },
    [stateValidateRes, stateSectionId, stateGenEditRes, stateSpeEditRes]
  );

  

  const publishButtonEnableCheck = stateIsEditGeneralRef.current
    ? validateResources && validateResources.general &&validateResources.general.length !== validateResources.general.filter(Boolean).length
    : validateResources && validateResources.specific[stateSectionId.current] &&
    validateResources.specific[stateSectionId.current].length !==
    validateResources.specific[stateSectionId.current].filter(Boolean).length;

  const setResourceItem = (data, i) => {
    const clonedList = stateIsEditGeneralRef.current ? stateGenEditRes.current : stateSpeEditRes.current;
    if(clonedList !== undefined){
      clonedList[i] = data;
      if (stateIsEditGeneralRef.current) {
        setGeneralEditResourceList(clonedList);
        stateGenEditRes.current = clonedList;
      } else {
        setSpecificEditResourceList(clonedList);
        stateSpeEditRes.current = clonedList;
      }
    }
  };




  const removeResourceItem = (id) => {
    let clonedList = stateIsEditGeneralRef.current ? stateGenEditRes.current : stateSpeEditRes.current;
    if(clonedList !== undefined){
      let trimmedList;
      const idx = clonedList.findIndex((item) => item.resourceId === id);
      const clonedValidateResourceList = stateIsEditGeneralRef.current
        ? [...stateValidateRes.current.general]
        : [...stateValidateRes.current.specific[stateSectionId.current]];
      clonedValidateResourceList.splice(idx, 1);
      const updatedValidateResourceList = stateIsEditGeneralRef.current
        ? { general: clonedValidateResourceList, specific: { ...stateValidateRes.current.specific } }
        : {
            general: [...stateValidateRes.current.general],
            specific: { ...stateValidateRes.current.specific, [stateSectionId.current]: clonedValidateResourceList },
          };
          setValidateResources(updatedValidateResourceList);
          stateValidateRes.current = updatedValidateResourceList;
  
      if (clonedList.length > 1) trimmedList = clonedList.filter((item) => item.resourceId !== id);
  
      if (stateIsEditGeneralRef.current) {
        setGeneralEditResourceList(trimmedList);
        stateGenEditRes.current = trimmedList;
      } else {
        setSpecificEditResourceList(trimmedList);
        stateSpeEditRes.current = trimmedList;
      }
    }
  };

  const handlePublishResources = () => {
    setIsPublishing(true);

    if (stateIsEditGeneralRef.current) {
      const videos = stateGenEditRes.current?.filter((item) => item.resourceType === "video");
      const pdfs = stateGenEditRes.current?.filter((item) => item.resourceType === "pdf");
      const urls = stateGenEditRes.current?.filter((item) => item.resourceType === "url");
      let videoStorageRef;
      let pdfStorageRef;
      let promises = [];

      if (typeof videos !== "undefined" && videos.length >= 1) {
        videos.forEach((resource, i) => {
          if (resource.filePath && !resource.filePath.startsWith("blob")) {
            return;
          }
          function uploadTaskPromise() {
            return new Promise(function(resolve, reject) {
              videoStorageRef = fs.ref(
                "resources/" + currentBoard + "/general/" + resource.resourceTitle.replace(/\s+/g, "-").toLowerCase()
              );
              let uploadTask = videoStorageRef.put(resource.resourceVideoFile[0]);
             
              var subscribe = uploadTask.on("state_changed");
              subscribe({
                'complete': async function() {
                  const videoUrl = await uploadTask.snapshot.ref.getDownloadURL();
                  videos[i].filePath = videoUrl;
                  delete videos[i].resourceVideoFile;
                  resolve();
                  }
              });
            });
          }
          promises.push(uploadTaskPromise());
        });
      }

      if (typeof pdfs !== "undefined" && pdfs.length >= 1) {
        pdfs.forEach((resource, i) => {
          if (resource.filePath && !resource.filePath.startsWith("blob")) {
            return;;
          }
          function uploadTaskPromise() {
            return new Promise(function(resolve, reject) {
              pdfStorageRef = fs.ref(
                "resources/" + currentBoard + "/general/" + resource.resourceTitle.replace(/\s+/g, "-").toLowerCase() + ".pdf"
              );
  
              let uploadTask = pdfStorageRef.put(resource.resourcePdfFile[0]);
              
              
              var subscribe = uploadTask.on("state_changed");
              subscribe({
                'complete': async function() {
                  const pdfUrl = await uploadTask.snapshot.ref.getDownloadURL();
                  pdfs[i].filePath = pdfUrl;
                  delete pdfs[i].resourcePdfFile;
                  resolve();
                  }
               });
            });
          }
          promises.push(uploadTaskPromise());
        });
      }

      Promise.all(promises).then(() => {
        let resourceData;
        if (typeof videos === "undefined" && typeof pdfs === "undefined" && typeof urls === "undefined") {
          resourceData = { general: [], specific: resources.specific }
        } else {
          resourceData = { general: [...videos, ...pdfs, ...urls], specific: resources.specific }
        }

        // convert your object into a JSON-string
        var jsonString = JSON.stringify(resourceData);
        // create a Blob from the JSON-string
        var blob = new Blob([jsonString], {type: "application/json"})

        const storageRef = fs.ref().child(currentBoard);
        const standardsFilePathRef = storageRef.child("resources.json");
        standardsFilePathRef.put(blob).then(function(snapshot) {
          toast.success("Resources have been published", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          //setIsEditingGeneralResources(false);
          stateIsEditGeneralRef.current = false;
          setIsEditingGeneralResources(false);
          setIsPublishing(false);
          setBoardResources(resourceData);
          setGeneralResourceList(resourceData.general);
        });
      });
    } else {
      const videos = stateSpeEditRes.current?.filter((item) => item.resourceType === "video");
      const pdfs = stateSpeEditRes.current?.filter((item) => item.resourceType === "pdf");
      const urls = stateSpeEditRes.current?.filter((item) => item.resourceType === "url");
      let videoStorageRef;
      let pdfStorageRef;
      let promises = [];

      if (typeof videos !== "undefined" && videos.length >= 1) {
        videos.forEach((resource, i) => {
          if (!resource.filePath.startsWith("blob")) {
            return;
          }
          function uploadTaskPromise() {
            return new Promise(function(resolve, reject) {
              videoStorageRef = fs.ref(
                "resources/" +
                  currentBoard +
                  "/specific/" +
                  stateSectionId.current +
                  "/" +
                  resource.resourceTitle.replace(/\s+/g, "-").toLowerCase()
              );

              let uploadTask = videoStorageRef.put(resource.resourceVideoFile[0]);
              var subscribe = uploadTask.on("state_changed");
              subscribe({
                'complete': async function() {
                  const videoUrl = await uploadTask.snapshot.ref.getDownloadURL();
                  videos[i].filePath = videoUrl;
                  delete videos[i].resourceVideoFile;
                  resolve();
                  }
              });
            });
          };
          promises.push(uploadTaskPromise());
        });
      } 

      if (typeof pdfs !== "undefined" && pdfs.length >= 1) {
        pdfs.forEach((resource, i) => {
          if (!resource.filePath.startsWith("blob")) {
            return;
          }
          function uploadTaskPromise() {
            return new Promise(function(resolve, reject) {
              pdfStorageRef = fs.ref(
                "resources/" +
                  currentBoard +
                  "/specific/" +
                  stateSectionId.current +
                  "/" +
                  resource.resourceTitle.replace(/\s+/g, "-").toLowerCase() + ".pdf"
              );

              let uploadTask = pdfStorageRef.put(resource.resourcePdfFile[0]);

              var subscribe = uploadTask.on("state_changed");
                subscribe({
                  'complete': async function() {
                      const pdfUrl = await uploadTask.snapshot.ref.getDownloadURL();
                      pdfs[i].filePath = pdfUrl;
                      delete pdfs[i].resourcePdfFile;
                      resolve();
                    }
                });
            });
          }
          promises.push(uploadTaskPromise());
        });
      }

      Promise.all(promises).then(() => {
        let resourceData;
        if (typeof videos === "undefined" && typeof pdfs === "undefined" && typeof urls === "undefined") {
          resourceData = {
            general: resources.general,
            specific: { ...resources.specific, [stateSectionId.current]: [] },
          }
        } else {
          resourceData = {
            general: resources.general,
            specific: { ...resources.specific, [stateSectionId.current]: [...videos, ...pdfs, ...urls] },
          }
        }
        // convert your object into a JSON-string
        var jsonString = JSON.stringify(resourceData);
        // create a Blob from the JSON-string
        var blob = new Blob([jsonString], {type: "application/json"})

        const storageRef = fs.ref().child(currentBoard);
        const standardsFilePathRef = storageRef.child("resources.json");
        standardsFilePathRef.put(blob).then(function(snapshot) {
          toast.success("Resources have been published", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          setIsEditingSpecificResources(false);
          stateIsEditSpecificRef.current = false;
          setIsPublishing(false);
          setBoardResources(resourceData);
          setSpecificResourceList(resourceData.specific[stateSectionId.current]);
        });

      });
    }
  };

  const handleToggleGeneralEdit = (isEditGeneral) => {
    if (!isEditGeneral) {
      setGeneralEditResourceList([]);
      stateGenEditRes.current = [];
      setGeneralResourceList(generalResourceList);
    } else {
      const guid = uuidv4();
      setGeneralEditResourceList(generalResourceList.length === 0 ? [...generalResourceList, { resourceType: "pdf", resourceId: guid }] : [...generalResourceList]);
      stateGenEditRes.current = generalResourceList.length === 0 ? [...generalResourceList, { resourceType: "pdf", resourceId: guid }] : [...generalResourceList];
    }
    setValidateResources({
      general: resourceList.general ? resourceList.general.map(() => true): [] ,
      specific: getSpecificSectionNames(resourceList.specific),
    });
    stateValidateRes.current = {
      general: resourceList.general ? resourceList.general.map(() => true):[] ,
      specific: getSpecificSectionNames(resourceList.specific),
    };
    stateIsEditGeneralRef.current = isEditGeneral;
    setIsEditingGeneralResources(isEditGeneral)
  };

  const handleToggleSpecificEdit = (isSpecificEdit) => {
    if (!isSpecificEdit) {
      setSpecificEditResourceList([]);
      stateSpeEditRes.current = [];
    } else {
      const guid = uuidv4();
      setSpecificEditResourceList(
        specificResourceList && specificResourceList.length === 0 ? [...specificResourceList, { resourceType: "pdf", resourceId: guid }] : [...specificResourceList]
      );
      stateSpeEditRes.current = specificResourceList && specificResourceList.length === 0 ? [...specificResourceList, { resourceType: "pdf", resourceId: guid }] : [...specificResourceList];
    }
    setValidateResources(
      {
        general: resourceList.general? resourceList.general.map(() => true):[],
        specific: getSpecificSectionNames(resourceList.specific),
      }
    );
    stateValidateRes.current = {
      general: resourceList.general? resourceList.general.map(() => true):[],
      specific: getSpecificSectionNames(resourceList.specific),
    };
    stateIsEditSpecificRef.current = isSpecificEdit;
    setIsEditingSpecificResources(isSpecificEdit);
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    if(stateIsEditGeneralRef.current === true){
      stateGenEditRes.current = update(stateGenEditRes.current, {
        $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, stateGenEditRes.current[dragIndex]],
        ],
      });
      setGeneralEditResourceList(stateGenEditRes.current);
    }
    else{
      stateSpeEditRes.current = update(stateSpeEditRes.current, {
        $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, stateSpeEditRes.current[dragIndex]],
        ],
      });
      setSpecificEditResourceList(stateSpeEditRes.current);
    }

}, []);

  const renderCard = useCallback((resource, i) => {
    return (
      <ResourceInputItem
        existingResource={resource}
        key={resource.resourceId || i}
        index={i}
        setResourceItem={setResourceItem}
        removeResourceItem={removeResourceItem}
        setShowVideo={setShowVideo}
        updateValidationItem={updateValidationItem}
        moveCard={moveCard}
      />
    );
  }, []);

  return (
    <div>
      {isPublishing && (
        <div className="loader-full-bg loader-full-height">
          <div className="loader loader-lg" />
        </div>
      )}

      <div style={{ padding: "0 24px" }}>
        <InfoWell />
      </div>

      <div className="spacer-24"></div>

      <div className="resource-section">
        <div className="section-header">
          <span className="title">General</span>
          <div style={{ height: "100%" }}>
            {currentUser && currentUser.admin && (
              <button
                className="btn"
                onClick={() => {
                  //setIsEditingGeneralResources(!isEditingGeneralResources);
                  handleToggleGeneralEdit(!stateIsEditGeneralRef.current);
                  setGeneralExpanded(true);
                }}
                disabled={isEditingSpecificResources}
                aria-label="toggle edit"
              >
                {!isEditingGeneralResources ? "Add/Edit" : "Cancel"}
              </button>
            )}
            <button className="toggle-section" onClick={() => setGeneralExpanded(!generalExpanded)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transition: "var(--default-transition)",
                  transform: generalExpanded ? "rotate(-180deg)" : "rotate(0)",
                }}
                aria-label="expand"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7055 14.2055C12.315 14.596 11.6818 14.596 11.2913 14.2055L8.46288 11.377C8.07235 10.9865 8.07235 10.3533 8.46288 9.96282C8.8534 9.57229 9.48657 9.57229 9.87709 9.96282L11.9984 12.0841L14.1197 9.96282C14.5103 9.57229 15.1434 9.57229 15.5339 9.96282C15.9245 10.3533 15.9245 10.9865 15.5339 11.377L12.7055 14.2055Z"
                  fill="var(--md_gray)"
                />
              </svg>
            </button>
          </div>
        </div>

        <motion.div
          style={{ overflow: "hidden" }}
          animate={{ height: generalExpanded ? "100%" : "0" }}
          initial={{ height: "100%" }}
          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
        >
          <div className="section-content">
            {!isEditingGeneralResources && resources && generalResourceList.length === 0 && (
              <div className="empty-section">
                <div className="bucket-icon">
                  <svg width="50" height="42" viewBox="0 0 50 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17 18.9997C17 17.3429 18.3431 15.9997 20 15.9997H30C31.6569 15.9997 33 17.3429 33 18.9997C33 20.6566 31.6569 21.9997 30 21.9997H20C18.3431 21.9997 17 20.6566 17 18.9997ZM20 17.9997C19.4477 17.9997 19 18.4474 19 18.9997C19 19.552 19.4477 19.9997 20 19.9997H30C30.5523 19.9997 31 19.552 31 18.9997C31 18.4474 30.5523 17.9997 30 17.9997H20Z"
                      fill="var(--md_gray)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 0C1.34315 0 0 1.34315 0 3V7C0 8.65685 1.34315 10 3 10H4.17061C4.06012 10.3127 4 10.6492 4 10.9997V38.9997C4 40.6566 5.34315 41.9997 7 41.9997H43C44.6569 41.9997 46 40.6566 46 38.9997V10.9997C46 10.6492 45.9399 10.3127 45.8294 10H47C48.6569 10 50 8.65685 50 7V3C50 1.34315 48.6569 0 47 0H3ZM6.976 10H43.024C43.5652 10.0127 44 10.4555 44 10.9997V38.9997C44 39.552 43.5523 39.9997 43 39.9997H7C6.44772 39.9997 6 39.552 6 38.9997V10.9997C6 10.4555 6.4348 10.0127 6.976 10ZM43.0416 8H47C47.5523 8 48 7.55228 48 7V3C48 2.44772 47.5523 2 47 2H3C2.44772 2 2 2.44772 2 3V7C2 7.55228 2.44772 8 3 8H6.95842C6.97226 7.99981 6.98612 7.99972 7 7.99972H43C43.0139 7.99972 43.0277 7.99981 43.0416 8Z"
                      fill="var(--md_gray)"
                    />
                  </svg>
                </div>

                <div className="spacer-24" />

                <p className="xs c-md_gray">There are currently no general resources.</p>

                {currentUser && currentUser.admin && (
                  <>
                    <div className="spacer-12" />

                    <p className="xs c-md_gray">You can add videos, PDFs, and links.</p>

                    <div className="spacer-24" />

                    <button
                      className="btn btn-blue btn-block"
                      onClick={() => {
                        //setIsEditingGeneralResources(true);
                        handleToggleGeneralEdit(true);
                      }}
                      disabled={isEditingSpecificResources}
                      aria-label="toggle edit"
                    >
                      <span className="icon-circle">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H11V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H13V9Z"
                            fill="var(--white)"
                          />
                        </svg>
                      </span>
                      <div className="spacer-12" />
                      Add resources
                    </button>
                  </>
                )}
              </div>
            )}

            {!isEditingGeneralResources && resources && generalResourceList.length >= 1 && (
              <>
                {resources && generalResourceList.filter((item) => item.resourceType === "video").length >= 1 && (
                  <div className="media-section-group">
                    <h6>Videos</h6>
                    <div className="spacer-20" />

                    {generalResourceList
                      .filter((item) => item.resourceType === "video")
                      .map((resource, i) => {
                        return (
                          <ResourceItem
                            key={i}
                            type={resource.resourceType}
                            title={resource.resourceTitle}
                            duration={resource.videoDuration}
                            size={resource.fileSize}
                            filePath={resource.filePath}
                            videoPreviewImg={resource.resourceVideoPreviewImg}
                            setShowVideo={setShowVideo}
                          />
                        );
                      })}
                  </div>
                )}

                {resources && generalResourceList.filter((item) => item.resourceType === "pdf").length >= 1 && (
                  <div className="media-section-group">
                    <h6>PDFs</h6>
                    <div className="spacer-20" />

                    {generalResourceList
                      .filter((item) => item.resourceType === "pdf")
                      .map((resource, i) => {
                        return (
                          <ResourceItem
                            key={i}
                            type={resource.resourceType}
                            title={resource.resourceTitle}
                            size={resource.fileSize}
                            filePath={resource.filePath}
                            pdfPreviewImg={resource.resourcePdfPreviewImg}
                          />
                        );
                      })}
                  </div>
                )}

                {resources && generalResourceList.filter((item) => item.resourceType === "url").length >= 1 && (
                  <div className="media-section-group">
                    <h6>Links</h6>
                    <div className="spacer-20" />

                    {generalResourceList
                      .filter((item) => item.resourceType === "url")
                      .map((resource, i) => {
                        return (
                          <ResourceItem
                            key={i}
                            type={resource.resourceType}
                            title={resource.resourceTitle}
                            urlPath={resource.resourceUrl}
                          />
                        );
                      })}
                  </div>
                )}
              </>
            )}

            {isEditingGeneralResources && (
              <>
                <h6>Manage resources</h6>

                <div className="spacer-20" />

                
                  <div>
                  
                    {resources &&
                      generalEditResourceList &&
                      generalEditResourceList.map((resource, i) => renderCard(resource, i))}
                  </div>

                  {generalEditResourceList && <div className="spacer-24"></div>}

                  <button
                    className="btn btn-outline btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      const guid = uuidv4();
                      if (!generalEditResourceList) {
                        setGeneralEditResourceList([{ resourceType: "pdf", resourceId: guid }]);
                        stateGenEditRes.current = [{ resourceType: "pdf", resourceId: guid }];
                      } else {
                        setGeneralEditResourceList([
                          ...generalEditResourceList,
                          { resourceType: "pdf", resourceId: guid },
                        ]);
                        stateGenEditRes.current = [
                          ...stateGenEditRes.current,
                          { resourceType: "pdf", resourceId: guid },
                        ];
                      }
                      setValidateResources({ ...stateValidateRes.current, general: [...stateValidateRes.current.general, false] });
                      stateValidateRes.current = { ...stateValidateRes.current, general: [...stateValidateRes.current.general, false] };
                    }}
                    aria-label="add resource"
                  >
                    {generalEditResourceList ? "Add another resource" : "Add resource"}
                  </button>

                  <div className="spacer-12" />

                  <button
                    type="submit"
                    className="btn btn-blue btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePublishResources();
                    }}
                    disabled={publishButtonEnableCheck}
                    aria-label="publish resources"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.2924 3.46452C11.6829 3.074 12.3161 3.074 12.7066 3.46452L15.535 6.29295C15.9256 6.68348 15.9256 7.31664 15.535 7.70716C15.1445 8.09769 14.5114 8.09769 14.1208 7.70716L13 6.58633L13 15.0001C13 15.5524 12.5523 16.0001 12 16.0001C11.4477 16.0001 11 15.5524 11 15.0001L11 6.58536L9.87819 7.70716C9.48767 8.09769 8.8545 8.09769 8.46398 7.70716C8.07345 7.31664 8.07345 6.68348 8.46398 6.29295L11.2924 3.46452Z"
                        fill="var(--white)"
                      />
                      <path
                        d="M6 13.0001C6 12.4478 5.55228 12.0001 5 12.0001C4.44772 12.0001 4 12.4478 4 13.0001V17.0001C4 18.6569 5.34315 20.0001 7 20.0001H17C18.6569 20.0001 20 18.6569 20 17.0001V13.0001C20 12.4478 19.5523 12.0001 19 12.0001C18.4477 12.0001 18 12.4478 18 13.0001V17.0001C18 17.5524 17.5523 18.0001 17 18.0001H7C6.44772 18.0001 6 17.5524 6 17.0001V13.0001Z"
                        fill="var(--white)"
                      />
                    </svg>
                    <div className="spacer-12" />
                    Publish resources
                  </button>

              </>
            )}
          </div>
        </motion.div>
      </div>

      <div className="resource-section">
        <div className="section-header">
          <span className="title">Specific</span>
          <div style={{ height: "100%" }}>
            {currentUser && currentUser.admin && (
              <button
                className="btn"
                onClick={() => {
                  //setIsEditingSpecificResources(!isEditingSpecificResources);
                  handleToggleSpecificEdit(!isEditingSpecificResources);
                  setSpecificExpanded(true);
                }}
                disabled={isEditingGeneralResources}
                aria-label="toggle edit"
              >
                {!isEditingSpecificResources ? "Add/Edit" : "Cancel"}
              </button>
            )}
            <button className="toggle-section" onClick={() => setSpecificExpanded(!specificExpanded)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transition: "var(--default-transition)",
                  transform: specificExpanded ? "rotate(-180deg)" : "rotate(0)",
                }}
                aria-label="toggle section"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7055 14.2055C12.315 14.596 11.6818 14.596 11.2913 14.2055L8.46288 11.377C8.07235 10.9865 8.07235 10.3533 8.46288 9.96282C8.8534 9.57229 9.48657 9.57229 9.87709 9.96282L11.9984 12.0841L14.1197 9.96282C14.5103 9.57229 15.1434 9.57229 15.5339 9.96282C15.9245 10.3533 15.9245 10.9865 15.5339 11.377L12.7055 14.2055Z"
                  fill="var(--md_gray)"
                />
              </svg>
            </button>
          </div>
        </div>

        <motion.div
          style={{ overflow: "hidden" }}
          animate={{ height: specificExpanded ? "100%" : "0" }}
          initial={{ height: "100%" }}
          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
        >
          <div className="section-content">
            {!isEditingSpecificResources && resources && specificResourceList && specificResourceList.length === 0 && (
              <div className="empty-section">
                <div className="bucket-icon">
                  <svg width="50" height="42" viewBox="0 0 50 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17 18.9997C17 17.3429 18.3431 15.9997 20 15.9997H30C31.6569 15.9997 33 17.3429 33 18.9997C33 20.6566 31.6569 21.9997 30 21.9997H20C18.3431 21.9997 17 20.6566 17 18.9997ZM20 17.9997C19.4477 17.9997 19 18.4474 19 18.9997C19 19.552 19.4477 19.9997 20 19.9997H30C30.5523 19.9997 31 19.552 31 18.9997C31 18.4474 30.5523 17.9997 30 17.9997H20Z"
                      fill="var(--md_gray)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 0C1.34315 0 0 1.34315 0 3V7C0 8.65685 1.34315 10 3 10H4.17061C4.06012 10.3127 4 10.6492 4 10.9997V38.9997C4 40.6566 5.34315 41.9997 7 41.9997H43C44.6569 41.9997 46 40.6566 46 38.9997V10.9997C46 10.6492 45.9399 10.3127 45.8294 10H47C48.6569 10 50 8.65685 50 7V3C50 1.34315 48.6569 0 47 0H3ZM6.976 10H43.024C43.5652 10.0127 44 10.4555 44 10.9997V38.9997C44 39.552 43.5523 39.9997 43 39.9997H7C6.44772 39.9997 6 39.552 6 38.9997V10.9997C6 10.4555 6.4348 10.0127 6.976 10ZM43.0416 8H47C47.5523 8 48 7.55228 48 7V3C48 2.44772 47.5523 2 47 2H3C2.44772 2 2 2.44772 2 3V7C2 7.55228 2.44772 8 3 8H6.95842C6.97226 7.99981 6.98612 7.99972 7 7.99972H43C43.0139 7.99972 43.0277 7.99981 43.0416 8Z"
                      fill="var(--md_gray)"
                    />
                  </svg>
                </div>

                <div className="spacer-24" />

                <p className="xs c-md_gray">
                  There are currently no specific resources for this section.
                </p>

                {currentUser && currentUser.admin && (
                  <>
                    <div className="spacer-12" />

                    <p className="xs c-md_gray">You can add videos, PDFs, and links.</p>
                    <div className="spacer-24" />

                    <button
                      className="btn btn-blue btn-block"
                      onClick={() => {
                        //setIsEditingSpecificResources(true);
                        handleToggleSpecificEdit(true);
                      }}
                      disabled={isEditingGeneralResources}
                      aria-label="edit resources"
                    >
                      <span className="icon-circle">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H11V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H13V9Z"
                            fill="var(--white)"
                          />
                        </svg>
                      </span>
                      <div className="spacer-12" />
                      Add resources
                    </button>
                  </>
                )}
              </div>
            )}

            {!isEditingSpecificResources && resources && specificResourceList && specificResourceList.length >= 1 && (
              <>
                {resources && specificResourceList.filter((item) => item.resourceType === "video").length >= 1 && (
                  <div className="media-section-group">
                    <h6>Videos</h6>
                    <div className="spacer-20" />

                    {specificResourceList &&
                      specificResourceList
                        .filter((item) => item.resourceType === "video")
                        .map((resource, i) => {
                          return (
                            <ResourceItem
                              key={i}
                              type={resource.resourceType}
                              title={resource.resourceTitle}
                              duration={resource.videoDuration}
                              size={resource.fileSize}
                              filePath={resource.filePath}
                              videoPreviewImg={resource.resourceVideoPreviewImg}
                              setShowVideo={setShowVideo}
                            />
                          );
                        })}
                  </div>
                )}

                {resources && specificResourceList.filter((item) => item.resourceType === "pdf").length >= 1 && (
                  <div className="media-section-group">
                    <h6>PDFs</h6>
                    <div className="spacer-20" />

                    {specificResourceList
                      .filter((item) => item.resourceType === "pdf")
                      .map((resource, i) => {
                        return (
                          <ResourceItem
                            key={i}
                            type={resource.resourceType}
                            title={resource.resourceTitle}
                            duration={resource.videoDuration}
                            size={resource.fileSize}
                            filePath={resource.filePath}
                            urlPath={resource.resourceUrl}
                            pdfPreviewImg={resource.resourcePdfPreviewImg}
                            setShowVideo={setShowVideo}
                          />
                        );
                      })}
                  </div>
                )}

                {resources && specificResourceList.filter((item) => item.resourceType === "url").length >= 1 && (
                  <div className="media-section-group">
                    <h6>Links</h6>
                    <div className="spacer-20" />

                    {specificResourceList
                      .filter((item) => item.resourceType === "url")
                      .map((resource, i) => {
                        return (
                          <ResourceItem
                            key={i}
                            type={resource.resourceType}
                            title={resource.resourceTitle}
                            urlPath={resource.resourceUrl}
                          />
                        );
                      })}
                  </div>
                )}
              </>
            )}

            {isEditingSpecificResources && (
              <>
                <h6>Manage resources</h6>

                <div className="spacer-20" />

                
                  <div>
                      {resources &&
                      specificEditResourceList &&
                      specificEditResourceList.map((resource, i) => renderCard(resource, i))}  
                  </div>

                  {specificEditResourceList && <div className="spacer-24"></div>}

                  <button
                    className="btn btn-outline btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      const guid = uuidv4();
                      if (!specificEditResourceList) {
                        setSpecificEditResourceList([{ resourceType: "pdf", resourceId: guid}]);
                        stateSpeEditRes.current =[{ resourceType: "pdf", resourceId: guid }];
                      } else {
                        setSpecificEditResourceList([
                          ...stateSpeEditRes.current,
                          { resourceType: "pdf", resourceId: guid },
                        ]);
                        stateSpeEditRes.current =[
                          ...stateSpeEditRes.current,
                          { resourceType: "pdf", resourceId: guid },
                        ];
                      }
                      setValidateResources({
                        ...stateValidateRes.current,
                        specific: {
                          ...stateValidateRes.current.specific,
                          [stateSectionId.current]: [...stateValidateRes.current.specific[stateSectionId.current], false],
                        },
                      });
                      stateValidateRes.current = {
                        ...stateValidateRes.current,
                        specific: {
                          ...stateValidateRes.current.specific,
                          [stateSectionId.current]: [...stateValidateRes.current.specific[stateSectionId.current], false],
                        },
                      };
                    }}
                    aria-label="add resource"
                  >
                    {specificEditResourceList ? "Add another resource" : "Add resource"}
                  </button>

                  <div className="spacer-12" />

                  <button
                    type="submit"
                    className="btn btn-blue btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePublishResources();
                    }}
                    disabled={publishButtonEnableCheck}
                    aria-label="publish resources"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.2924 3.46452C11.6829 3.074 12.3161 3.074 12.7066 3.46452L15.535 6.29295C15.9256 6.68348 15.9256 7.31664 15.535 7.70716C15.1445 8.09769 14.5114 8.09769 14.1208 7.70716L13 6.58633L13 15.0001C13 15.5524 12.5523 16.0001 12 16.0001C11.4477 16.0001 11 15.5524 11 15.0001L11 6.58536L9.87819 7.70716C9.48767 8.09769 8.8545 8.09769 8.46398 7.70716C8.07345 7.31664 8.07345 6.68348 8.46398 6.29295L11.2924 3.46452Z"
                        fill="var(--white)"
                      />
                      <path
                        d="M6 13.0001C6 12.4478 5.55228 12.0001 5 12.0001C4.44772 12.0001 4 12.4478 4 13.0001V17.0001C4 18.6569 5.34315 20.0001 7 20.0001H17C18.6569 20.0001 20 18.6569 20 17.0001V13.0001C20 12.4478 19.5523 12.0001 19 12.0001C18.4477 12.0001 18 12.4478 18 13.0001V17.0001C18 17.5524 17.5523 18.0001 17 18.0001H7C6.44772 18.0001 6 17.5524 6 17.0001V13.0001Z"
                        fill="var(--white)"
                      />
                    </svg>
                    <div className="spacer-12" />
                    Publish resources
                  </button>
                
              </>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Resources;
