import React from "react";
import ReactTooltip from "react-tooltip";

const BackToTop = ({ backToTopRef, backToTop, showBackToTop, width }) => {
  return (
    <>
      <div className="back-to-top-wrapper">
        <button
          ref={backToTopRef}
          onClick={() => backToTop()}
          className={`btn btn-primary btn-circle btn-circle-md back-to-top ${showBackToTop && "shown"}`}
          data-for="backToTop"
          data-tip="Back to top"
          aria-label="back to top"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.2914 9.79289C11.6819 9.40237 12.3151 9.40237 12.7056 9.79289L15.534 12.6213C15.9245 13.0118 15.9245 13.645 15.534 14.0355C15.1435 14.4261 14.5103 14.4261 14.1198 14.0355L11.9985 11.9142L9.87715 14.0355C9.48663 14.4261 8.85346 14.4261 8.46294 14.0355C8.07241 13.645 8.07241 13.0118 8.46294 12.6213L11.2914 9.79289Z"
              fill="var(--white)"
            />
          </svg>
        </button>
      </div>
      <ReactTooltip className="tooltip-sm" id="backToTop" place="left" disable={width <= 992} />
    </>
  );
};

export default BackToTop;
