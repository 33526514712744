import React from "react";

const ContentPlaceholder = ({ type }) => {
  let placeholder;

  if (type === "toc") {
    placeholder = (
      <>
        <div className="paragraph-block md" />
        <div className="spacer-24" />
        <div className="paragraph-block lg" />
        <div className="spacer-24" />
        <div className="paragraph-block md" />
        <div className="spacer-24" />
        <div className="paragraph-block lg" />
        <div className="spacer-24" />
        <div className="paragraph-block lg" />
        <div className="spacer-24" />
        <div className="paragraph-block md" />
        <div className="spacer-24" />
        <div className="paragraph-block lg" />
        <div className="spacer-24" />
        <div className="paragraph-block md" />
        <div className="spacer-24" />
        <div className="paragraph-block md" />
      </>
    );
  } else if (type === "content") {
    placeholder = (
      <>
        <div className="heading-block" />
        <div className="spacer-32" />
        <div className="paragraph-block lg" />
        <div className="spacer-12" />
        <div className="paragraph-block lg" />
        <div className="spacer-12" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block lg" />
        <div className="spacer-12" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block sm" />

        <div className="spacer-32" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block lg" />
        <div className="spacer-12" />
        <div className="paragraph-block md" />

        <div className="spacer-32" />
        <div className="paragraph-block lg" />
        <div className="spacer-12" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block sm" />

        <div className="spacer-32" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block lg" />
        <div className="spacer-12" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block sm" />

        <div className="spacer-48" />

        <div className="heading-block" />
        <div className="spacer-32" />
        <div className="paragraph-block lg" />
        <div className="spacer-12" />
        <div className="paragraph-block sm" />
        <div className="spacer-12" />
        <div className="paragraph-block lg" />
        <div className="spacer-12" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block sm" />

        <div className="spacer-32" />
        <div className="paragraph-block md" />
        <div className="spacer-12" />
        <div className="paragraph-block sm" />
        <div className="spacer-12" />
        <div className="paragraph-block lg" />
      </>
    );
  } else if (type === "panel") {
    placeholder = (
      <>
        <div className="paragraph-block lg" />
        <div className="spacer-24" />
        <div className="paragraph-block lg" />
        <div className="spacer-24" />
        <div className="paragraph-block sm" />
        <div className="spacer-24" />
        <div className="paragraph-block md" />
        <div className="spacer-24" />
        <div className="paragraph-block lg" />
        <div className="spacer-24" />
        <div className="paragraph-block sm" />
        <div className="spacer-24" />
        <div className="paragraph-block md" />
        <div className="spacer-24" />
        <div className="paragraph-block md" />
        <div className="spacer-24" />
        <div className="paragraph-block sm" />
        <div className="spacer-24" />
        <div className="paragraph-block lg" />
        <div className="spacer-24" />
        <div className="paragraph-block md" />
      </>
    );
  }

  return placeholder;
};

export default ContentPlaceholder;
