import PDF from "../assets/pdfs/ClipboardPermissions.pdf";
import React from "react";

const PDFLink = ({ linkText }) => {
  return (
    <a href={PDF} target="_blank" rel="noopener noreferrer">
      {linkText}
    </a>
  );
};

export default PDFLink;
