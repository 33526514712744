import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modal = {
  hidden: { y: "0", opacity: 0 },
  visible: {
    y: "200px",
    opacity: 1,
    transition: { delay: 0.4 },
  },
};

const Modal = ({ children, show, close, title, showCloseBtn, backdropDismiss, isVideo }) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className={`backdrop ${isVideo && "is-video-backdrop"}`}
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
          onClick={(e) => {
            if (backdropDismiss) {
              e.stopPropagation();
              close();
            }
          }}
          style={{ cursor: backdropDismiss ? "pointer" : "initial" }}
        >
          <motion.div
            className={`modal ${isVideo && "is-video"}`}
            variants={modal}
            transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex space-between align-center">
              <h4>{title}</h4>
              {showCloseBtn && (
                <button onClick={() => close()} className="btn btn-secondary btn-circle btn-circle-sm" aria-label="close">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.2427 9.17146C16.6332 8.78094 16.6332 8.14777 16.2427 7.75725C15.8521 7.36672 15.219 7.36672 14.8284 7.75725L11.9999 10.5858L9.17158 7.7574C8.78106 7.36688 8.1479 7.36688 7.75737 7.7574C7.36685 8.14792 7.36685 8.78109 7.75737 9.17161L10.5857 12L7.75738 14.8283C7.36686 15.2188 7.36685 15.852 7.75738 16.2425C8.1479 16.6331 8.78107 16.6331 9.17159 16.2425L11.9999 13.4142L14.8284 16.2427C15.219 16.6332 15.8521 16.6332 16.2427 16.2427C16.6332 15.8522 16.6332 15.219 16.2427 14.8285L13.4142 12L16.2427 9.17146Z"
                      fill="var(--md_gray)"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div className="spacer-20" />
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
