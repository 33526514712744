import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Switch } from "react-router-dom";

export const RouteTransitionWrapper = ({ amount = 0, children }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: amount,
      }}
      exit={{
        opacity: 0,
        y: amount,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
    >
      {children}
    </motion.div>
  );
};

export const AnimatedRoutes = ({ children }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch>{children}</Switch>
    </AnimatePresence>
  );
};
