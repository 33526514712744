import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Scrollspy from "react-scrollspy";
import MenuItem from "./MenuItem";
import { useWindowWidth } from "../hooks/useWindowWidth";
import ContentPlaceholder from "./ContentPlaceholder";
import { useParams } from "react-router-dom";


const TOC = ({
  isLoading,
  currentBoard,
  currentVersion,
  currentStandard,
  boardVersions,
  TOCRef,
  scrollSpyItems,
  handleBoardNavUpdate,
  handleVersionNavUpdate,
  showMobileTOC,
  getCurrentSection,
  headroomRef,
  currentSectionId,
}) => {
  const width = useWindowWidth();
  const { board: boardFromParam } = useParams();
  return (
    <aside
      className={"nav-toc " + (showMobileTOC ? "toc-open-mobile " : "toc-closed-mobile ")}
      aria-label="standard boards"
    >


      <div className="toc-list" ref={TOCRef}>
        {isLoading && (
          <div style={{ marginLeft: 24 }}>
            <div className="spacer-16" />
            <ContentPlaceholder type="toc" />
          </div>
        )}
        {!isLoading && currentStandard !== null && (
          <Scrollspy
            componentTag="div"
            rootEl=".main-content"
            items={scrollSpyItems}
            onUpdate={(e) => {
              console.log("On Update", e);
            }}
            onEvent={(e) => {
              console.log("On Event", e);
            }}
            offEvent={(e) => {
              console.log("Off Event", e);
            }}
            currentClassName="active"
          >
            {currentStandard !== null &&
              currentStandard.toc &&
              currentStandard.toc[0].map((item) => (
                <MenuItem
                  key={item.id + item.name}
                  item={item}
                  currentStandard={currentStandard}
                  getCurrentSection={getCurrentSection}
                  headroomRef={headroomRef}
                  currentSectionId={currentSectionId}
                />
              ))}
          </Scrollspy>
        )}
      </div>

      <div className="footer-options blur-bg-white">
        {boardFromParam==='pathways'?(
          <a href="https://www.ifac.org/system/files/pathways/pathways-accrual-guidance.pdf" target="_blank" rel="noopener noreferrer">
            Get PDF
          </a>
        ):(
          <a href="https://www.ifac.org/who-we-are/membership" target="_blank" rel="noopener noreferrer">
          Get PDF
        </a>
        )}
        

        <a href="https://www.ifac.org/who-we-are/operations" target="_blank" rel="noopener noreferrer">
          Permissions policy
        </a>

        {boardFromParam==='pathways'?(
          <a href="https://www.ifac.org/contact-pathways-to-accrual" target="_blank" rel="noopener noreferrer">
            Contact IFAC
          </a>
        ):(
          <a href="https://www.ifac.org/who-we-are/membership/contact-membership" target="_blank" rel="noopener noreferrer">
            Contact IFAC
          </a>
        )}

        <a href="https://www.ifac.org/eis-faqs" target="_blank" rel="noopener noreferrer">
          FAQs
        </a>
        <a href="https://www.ifac.org" className="go-back">
          <span className="line"></span> Back to home
        </a>
      </div>
    </aside>
  );
};

export default TOC;
