import React from "react";
import "./styles";
import { throttle } from "lodash";
import { StoreProvider, createStore } from "easy-peasy";
import { storeModel } from "./model";
import { loadState, saveState } from "./model/localStorage";
import BaseRouter from "./baseRouter";

const persistedState = loadState();
const store = createStore({ ...storeModel, ...persistedState });

store.subscribe(
  throttle(() => {
    const persisted = (({ user, session }) => ({ user, session }))(store.getState());
    //console.log(persisted);
    saveState(persisted);
  }, 1000)
);

const App = () => {
  return (
    <StoreProvider store={store}>
      <BaseRouter />
    </StoreProvider>
  );
};

export default App;
