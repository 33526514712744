const userModel = {
  uid: "",
  loggedIn: false,
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  admin: false,
  board: "",
  sid: "",
};

export default userModel;
