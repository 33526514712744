import React, { useState } from "react";
import { motion } from "framer-motion";

const Accordion = ({ definition, id, expanded, setExpanded }) => {
  const isOpen = id === expanded;
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <motion.div
        initial={false}
        onClick={() => setExpanded(isOpen ? false : id)}
        onHoverStart={() => setHovered(!hovered)}
        onHoverEnd={() => setHovered(!hovered)}
        style={{ cursor: "pointer" }}
      >
        <div className={`accordion-toggle ${isOpen ? "active" : ""}`}>
          <span className="btn btn-secondary btn-sm btn-sq">
            {isOpen ? (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.99994 12C7.99994 11.4477 8.44765 11 8.99994 11H14.9999C15.5522 11 15.9999 11.4477 15.9999 12V12C15.9999 12.5523 15.5522 13 14.9999 13H8.99994C8.44765 13 7.99994 12.5523 7.99994 12V12Z"
                  fill={isOpen || hovered ? "var(--blue)" : "var(--md_gray)"}
                />
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H11V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H13V9Z"
                  fill={isOpen || hovered ? "var(--blue)" : "var(--md_gray)"}
                />
              </svg>
            )}
          </span>
          {definition.term}
        </div>
      </motion.div>

      <motion.div
        className="accordion-content"
        key="content"
        animate={{ height: isOpen ? "100%" : "0" }}
        transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
        hidden={{ height: 0 }}
      >
        <div className="accordion-content-inner">
          {definition.definition.map((def, i) => (
            <p key={i}>{def}</p>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default Accordion;
