import React from "react";

export const BookLoader = () => {
  const length = 18;
  let pages = [];

  for (let i = 0; i < length; i++) {
    pages.push(`Page: ${i}`);
  }

  return (
    <div className="book">
      <div className="inner">
        <div className="left"></div>
        <div className="middle"></div>
        <div className="right"></div>
      </div>
      <ul>
        {pages.map((_, i) => (
          <li key={i}></li>
        ))}
      </ul>
    </div>
  );
};
