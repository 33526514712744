const sessionModel = {
  acceptedTerms: false,
  currentBoard: "",
  currentVersion: "",
  currentSection: "",
  currentSectionId: "",
  currentStandard: [],
  recentSearches: {
    iaasb: ["Audit", "Firms", "Threat"], // remove before going live
    iesba: ["Audit", "Firms", "Threat"], // remove before going live
    ipsasb: ["Audit", "Firms", "Threat"], // remove before going live
  },
  commonSearches: {
    iaasb: [],
    iesba: [
      "Integrity",
      "Objectivity",
      "Professional Competence",
      "Confidentiality",
      "Professional Behavior",
      "Independence of Mind",
    ],
    ipsasb: [],
  },
};

export default sessionModel;
