import React, { useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ReactTooltip from "react-tooltip";
import { useWindowWidth } from "../hooks/useWindowWidth";

const FloatingPanel = (props) => {
  const width = useWindowWidth();
  const { show, close, references, title } = props;
  let floatingPanelRef = useRef(null);

  useEffect(() => {
    floatingPanelRef.current && dragElement(floatingPanelRef.current);
  }, [show]);

  const dragElement = (el) => {
    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    el.querySelector(".menu-bar").onmousedown = dragMouseDown;

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }

    const elementDrag = (e) => {
      e = e || window.event;
      e.preventDefault();
      const winWidth = window.innerWidth;
      const winHeight = window.innerHeight;
      const elWidth = el.offsetWidth;
      const elHeight = el.offsetHeight;
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      if (el.offsetTop - pos2 <= 24) {
        el.style.top = "24px";
      } else if (el.offsetTop - pos2 + 24 >= winHeight - elHeight) {
        el.style.top = winHeight - elHeight - 24 + "px";
      } else {
        el.style.top = el.offsetTop - pos2 + "px";
      }

      if (el.offsetLeft - pos1 <= 24) {
        el.style.left = "24px";
      } else if (el.offsetLeft - pos1 + 24 >= winWidth - elWidth) {
        el.style.left = winWidth - elWidth - 24 + "px";
      } else {
        el.style.left = el.offsetLeft - pos1 + "px";
      }
    };

    const closeDragElement = () => {
      document.onmouseup = null;
      document.onmousemove = null;
    };
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {show && (
        <motion.div
          className="floating-panel"
          ref={floatingPanelRef}
          initial={{
            opacity: 0,
            y: 48,
          }}
          exit={{
            opacity: 0,
            y: 48,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
        >
          <div className="floating-panel-inner">
            <div className="menu-bar">
              <p className="lead">{title}</p>

              <div className="menu-actions">
                <button className="btn btn-ghost btn-md btn-sq" style={{ cursor: "move" }} data-tip="Move" aria-label="menu actions">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.7069 4.39348C12.3164 4.00295 11.6832 4.00295 11.2927 4.39348L9.17139 6.5148C8.78087 6.90532 8.78087 7.53849 9.17139 7.92901C9.56191 8.31954 10.1951 8.31954 10.5856 7.92901L10.9998 7.5148V11.0001L7.51462 11.0001L7.92883 10.5859C8.31935 10.1954 8.31935 9.56219 7.92883 9.17167C7.53831 8.78114 6.90514 8.78114 6.51462 9.17167L4.3933 11.293C4.00277 11.6835 4.00277 12.3167 4.3933 12.7072L6.51462 14.8285C6.90514 15.219 7.53831 15.219 7.92883 14.8285C8.31935 14.438 8.31935 13.8048 7.92883 13.4143L7.51462 13.0001H10.9998L10.9998 16.4854L10.5856 16.0712C10.1951 15.6806 9.56191 15.6806 9.17139 16.0712C8.78087 16.4617 8.78087 17.0949 9.17139 17.4854L11.2927 19.6067C11.6832 19.9972 12.3164 19.9972 12.7069 19.6067L14.8282 17.4854C15.2188 17.0949 15.2188 16.4617 14.8282 16.0712C14.4377 15.6806 13.8046 15.6806 13.414 16.0712L12.9998 16.4854V13.0001H16.485L16.0708 13.4143C15.6803 13.8048 15.6803 14.438 16.0708 14.8285C16.4613 15.219 17.0945 15.219 17.485 14.8285L19.6063 12.7072C19.9969 12.3167 19.9969 11.6835 19.6063 11.293L17.485 9.17167C17.0945 8.78114 16.4613 8.78114 16.0708 9.17167C15.6803 9.56219 15.6803 10.1954 16.0708 10.5859L16.485 11.0001H12.9998V7.5148L13.414 7.92901C13.8046 8.31954 14.4377 8.31954 14.8282 7.92901C15.2188 7.53849 15.2188 6.90532 14.8282 6.5148L12.7069 4.39348Z"
                      fill="var(--white)"
                    />
                  </svg>
                </button>
                {/* <button
                  className="btn btn-ghost btn-md btn-sq"
                  onClick={() => console.log("new tab")}
                  data-tip="New tab"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.00006 7C7.44778 7 7.00006 7.44772 7.00006 8V16C7.00006 16.5523 7.44778 17 8.00006 17H16.0001C16.5523 17 17.0001 16.5523 17.0001 16V13C17.0001 12.4477 17.4478 12 18.0001 12C18.5523 12 19.0001 12.4477 19.0001 13V16C19.0001 17.6569 17.6569 19 16.0001 19H8.00006C6.34321 19 5.00006 17.6569 5.00006 16L5.00006 8C5.00006 6.34315 6.34321 5 8.00006 5L11.0001 5C11.5523 5 12.0001 5.44772 12.0001 6C12.0001 6.55229 11.5523 7 11.0001 7L8.00006 7Z"
                      fill="var(--white)"
                    />
                    <path
                      d="M12.757 11.2428C12.3664 10.8522 12.3664 10.2191 12.757 9.82854L15.4644 7.12109H14.879C14.3267 7.12109 13.879 6.67338 13.879 6.12109C13.879 5.56881 14.3267 5.12109 14.879 5.12109L17.879 5.12109C18.4313 5.12109 18.879 5.56881 18.879 6.12109V9.12109C18.879 9.67338 18.4313 10.1211 17.879 10.1211C17.3267 10.1211 16.879 9.67338 16.879 9.12109V8.53497L14.1712 11.2428C13.7807 11.6333 13.1475 11.6333 12.757 11.2428Z"
                      fill="var(--white)"
                    />
                  </svg>
                </button> */}
                <button className="btn btn-ghost btn-md btn-sq" onClick={() => close()} data-tip="Close" aria-label="close">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.2427 9.17146C16.6332 8.78094 16.6332 8.14777 16.2427 7.75725C15.8521 7.36672 15.219 7.36672 14.8284 7.75725L11.9999 10.5858L9.17158 7.7574C8.78106 7.36688 8.1479 7.36688 7.75737 7.7574C7.36685 8.14792 7.36685 8.78109 7.75737 9.17161L10.5857 12L7.75738 14.8283C7.36686 15.2188 7.36685 15.852 7.75738 16.2425C8.1479 16.6331 8.78107 16.6331 9.17159 16.2425L11.9999 13.4142L14.8284 16.2427C15.219 16.6332 15.8521 16.6332 16.2427 16.2427C16.6332 15.8522 16.6332 15.219 16.2427 14.8285L13.4142 12L16.2427 9.17146Z"
                      fill="var(--white)"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="panel-content">
              {/* <p className="content-title">The Role and Timing of Planning (Ref: Para. 2)</p> */}

              {references.length >= 1 &&
                references.map((ref, i) => (
                  <div key={ref.id + i} 
                  dangerouslySetInnerHTML={{ __html: "<p class='ref'>" + ref.id + "</p> <p>" + ref.content + "</p>" }}>
                  </div>
                ))}
            </div>
          </div>
          <ReactTooltip className="tooltip-sm" disable={width <= 992} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FloatingPanel;
