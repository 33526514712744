import React, { useState, useEffect } from "react";
import faviconMain from "../assets/favicon.ico";
import Logo from "../assets/eis_logo.jpg";
import IFACLogo from "../assets/ifac_logo.jpg";
import OfficeImg from "../assets/images/office-desk.jpg";
import OfficeMobileImg from "../assets/images/office-desk-mobile.jpg";
import DotsVertGray from "../assets/images/dots-vert-gray.png";
import ArrowRightIcon from "../assets/icons/arrow-right.svg";
import { Link, useHistory } from "react-router-dom";
import { RouteTransitionWrapper } from "../components";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useWindowWidth } from "../hooks/useWindowWidth";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Modal, PDFLink } from "../components";

const Home = () => {
  const history = useHistory();
  const width = useWindowWidth();


  const date = new Date();
  let months = [];
  months[0] = "January";
  months[1] = "February";
  months[2] = "March";
  months[3] = "April";
  months[4] = "May";
  months[5] = "June";
  months[6] = "July";
  months[7] = "August";
  months[8] = "September";
  months[9] = "October";
  months[10] = "November";
  months[11] = "December";
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const [showTerms, setShowTerms] = useState(false);
  const [showVideo, setShowVideo] = useState(false);


  const currentUser = useStoreState((state) => state.user);
  const currentSession = useStoreState((state) => state.session);

  const signIn = useStoreActions((actions) => actions.signIn);
  const signOut = useStoreActions((actions) => actions.signOut);
  const acceptTerms = useStoreActions((actions) => actions.acceptTerms);

  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errorDisabled, setErrorDisabled] = useState(true);
  const [btnClassName, setBtnClassName] = useState("btn btn-secondary");

  useEffect(() => {
    const favicon = document.querySelector("#favicon");
    favicon.href = faviconMain;
    const urlParams = new URLSearchParams(window.location.search);
    if (currentUser.loggedIn === false && currentUser.admin === false) history.push("/standards/pathways/2021");
    if(urlParams.get('sid')) {
      handleSignInSuccess()
    }
    if(urlParams.get('logout')) {
      handleSignOutSuccess()
    }
  }, []);

  const handleNavigateToBoard = (e) => {
    e.stopPropagation();
    let path = e.target.getAttribute("data-path");
    history.push(path);
  };

  const handleSignInRequest = async () => {
    const url = process.env.REACT_APP_LOGIN_URL;
    const bearerToken = process.env.REACT_APP_GCP_BEARER_TOKEN;
    const {host, protocol} = window.location;
    const relayState = `${protocol}//${host}`

    const response = await fetch(url, {
      method: "post",
      headers: {
        authorization: "Bearer " + bearerToken,
        "content-type": "application/json",
      },
      body: JSON.stringify({relayState}),
      mode: "cors",
    });

    response.text().then(url => window.location.replace(url));
  };

  const handleSignInSuccess = () => {
    toast.success("You are now signed in.", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const username = urlParams.get('name');
    const sid = urlParams.get('sid');
    const nameId = urlParams.get('nid');
    //const memberType = urlParams.get('memtype');
    const adminBoards = urlParams.get('admin');
    let boards = "";
    let adminBoard = "";
    let isAdmin = false;
    if(adminBoards && adminBoards.length > 0){ 
      if(adminBoards === "none"){
        isAdmin = false;
      }
      else{
        boards = adminBoards.split(",");
        if(boards.length > 0){ 
          adminBoard = boards[0].toLowerCase();
          isAdmin = true;
        };
      }
    };

    signIn({username, sid, nameId, isAdmin, adminBoard});

    // if (currentSession.acceptedTerms === false) {
    //   setShowTerms(true);
    // }
    history.push('/');
  }

  const handleSignOut = async () => {
    //history.push('/');
    const url = process.env.REACT_APP_LOGOUT_URL;
    const bearerToken = process.env.REACT_APP_GCP_BEARER_TOKEN;
    const {host, protocol} = window.location;
    const relayState = `${protocol}//${host}`;
    const body = {'session_index': currentUser.sid, 'name_id': currentUser.uid, 'relay_state': relayState};
    const reqBody = JSON.stringify(body);
    console.log(reqBody);
    const response = await fetch(url, {
      method: "post",
      headers: {
        authorization: "Bearer " + bearerToken,
        "content-type": "application/json",
      },
      body: reqBody,
      mode: "cors",
    });

    response.text().then(url => window.location.replace(url));

    // response.text().then(async (urlLogout) => {
    //   const res = await fetch(urlLogout, {
    //     method: "get",
    //     headers: {
    //       authorization: "Bearer " + bearerToken,
    //       "content-type": "application/json",
    //     },
    //     mode: "cors",
    //   }).then(function() {
    //     console.log("logout success");
    // }).catch(function() {
    //     console.log("logout error");
    // }).finally(() => {
    //   toast.success("You are now signed out.", {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //   });
    //   signOut();
    // });

      
    // });
  };

  const handleSignOutSuccess = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const loggedOut = urlParams.get('logout');
    if(loggedOut && loggedOut==="true"){
      signOut();
    }
  }

  return (
    <RouteTransitionWrapper>
      <Modal
        title="Standards tour"
        show={showVideo}
        close={() => setShowVideo(false)}
        showCloseBtn={false}
        backdropDismiss={true}
        isVideo={true}
      >
        <video className="video-player" width="100%" controls>
          <source className="video-player-src" src="https://storage.googleapis.com/ifac-digital-standards-pub.appspot.com/EIS_HomePageVideoFINAL.mp4" />
          Your browser does not support HTML5 video.
        </video>
      </Modal>

      <Modal
        title="Terms of use"
        show={showTerms}
        close={() => setShowTerms(false)}
        showCloseBtn={false}
        backdropDismiss={false}
      >
        <p className="sm">
          Copyright <span style={{ fontFamily: "Arial", fontWeight: "bolder" }}>©</span> {month} {year} by the
          International Federation of Accountants (IFAC). All rights reserved.
        </p>

        <div className="spacer-12" />

        <p className="sm">
          By using this digital platform, you agree to the following terms of use. These standards may be reproduced in
          part solely for professional and personal reference and research work.
        </p>

        <div className="spacer-12" />

        <p className="sm">
          For all other uses of the standards, including reproduction, translation, modification or adaptation, please
          direct permission requests to{" "}
          <a href="mailto:permissions@ifac.org" className="blue-link">
            permissions@ifac.org
          </a>
          .
        </p>

        <div className="spacer-12" />

        <p className="sm">
          <label>
            <input
              type="checkbox"
              checked={checked}
            />
            &nbsp;You must allow clipboard permissions to access the digital standards.
          </label>
        </p>

        <div className="spacer-12" />

        <p className="sm">
          <span style={{ color: "red", visibility: errorDisabled ? "hidden" : "visible" }}>
            Clipboard permission must be allowed. &nbsp;<PDFLink linkText="Help"></PDFLink>
          </span>
        </p>

        <div className="spacer-12" />

        <button
          disabled={disabled}
          className={btnClassName}
          onClick={() => {
            acceptTerms();
            setShowTerms(false);
            toast.info("You have accepted the terms of use.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }}
          aria-label="accept terms of use"
        >
          Accept terms
        </button>
      </Modal>

      <div className="flex-full-height" role="document">
        <header className="header space-between" role="banner">
          <div className="flex align-center">
            <a href="/" className="logo">
              <img src={Logo} alt="IFAC" />
            </a>

            <ul className="menu-nav">
              <li>
                <a href="https://www.iaasb.org/" target="_blank" rel="noopener noreferrer" data-tip="www.iaasb.org">
                  IAASB
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.00006 7C7.44778 7 7.00006 7.44772 7.00006 8V16C7.00006 16.5523 7.44778 17 8.00006 17H16.0001C16.5523 17 17.0001 16.5523 17.0001 16V13C17.0001 12.4477 17.4478 12 18.0001 12C18.5523 12 19.0001 12.4477 19.0001 13V16C19.0001 17.6569 17.6569 19 16.0001 19H8.00006C6.34321 19 5.00006 17.6569 5.00006 16L5.00006 8C5.00006 6.34315 6.34321 5 8.00006 5L11.0001 5C11.5523 5 12.0001 5.44772 12.0001 6C12.0001 6.55229 11.5523 7 11.0001 7L8.00006 7Z"
                      fill="var(--md_gray)"
                    />
                    <path
                      d="M12.757 11.2428C12.3664 10.8522 12.3664 10.2191 12.757 9.82854L15.4644 7.12109H14.879C14.3267 7.12109 13.879 6.67338 13.879 6.12109C13.879 5.56881 14.3267 5.12109 14.879 5.12109L17.879 5.12109C18.4313 5.12109 18.879 5.56881 18.879 6.12109V9.12109C18.879 9.67338 18.4313 10.1211 17.879 10.1211C17.3267 10.1211 16.879 9.67338 16.879 9.12109V8.53497L14.1712 11.2428C13.7807 11.6333 13.1475 11.6333 12.757 11.2428Z"
                      fill="var(--md_gray)"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.ethicsboard.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tip="www.ethicsboard.org"
                >
                  IESBA
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.00006 7C7.44778 7 7.00006 7.44772 7.00006 8V16C7.00006 16.5523 7.44778 17 8.00006 17H16.0001C16.5523 17 17.0001 16.5523 17.0001 16V13C17.0001 12.4477 17.4478 12 18.0001 12C18.5523 12 19.0001 12.4477 19.0001 13V16C19.0001 17.6569 17.6569 19 16.0001 19H8.00006C6.34321 19 5.00006 17.6569 5.00006 16L5.00006 8C5.00006 6.34315 6.34321 5 8.00006 5L11.0001 5C11.5523 5 12.0001 5.44772 12.0001 6C12.0001 6.55229 11.5523 7 11.0001 7L8.00006 7Z"
                      fill="var(--md_gray)"
                    />
                    <path
                      d="M12.757 11.2428C12.3664 10.8522 12.3664 10.2191 12.757 9.82854L15.4644 7.12109H14.879C14.3267 7.12109 13.879 6.67338 13.879 6.12109C13.879 5.56881 14.3267 5.12109 14.879 5.12109L17.879 5.12109C18.4313 5.12109 18.879 5.56881 18.879 6.12109V9.12109C18.879 9.67338 18.4313 10.1211 17.879 10.1211C17.3267 10.1211 16.879 9.67338 16.879 9.12109V8.53497L14.1712 11.2428C13.7807 11.6333 13.1475 11.6333 12.757 11.2428Z"
                      fill="var(--md_gray)"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.ipsasb.org/" target="_blank" rel="noopener noreferrer" data-tip="www.ipsasb.org">
                  IPSASB
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.00006 7C7.44778 7 7.00006 7.44772 7.00006 8V16C7.00006 16.5523 7.44778 17 8.00006 17H16.0001C16.5523 17 17.0001 16.5523 17.0001 16V13C17.0001 12.4477 17.4478 12 18.0001 12C18.5523 12 19.0001 12.4477 19.0001 13V16C19.0001 17.6569 17.6569 19 16.0001 19H8.00006C6.34321 19 5.00006 17.6569 5.00006 16L5.00006 8C5.00006 6.34315 6.34321 5 8.00006 5L11.0001 5C11.5523 5 12.0001 5.44772 12.0001 6C12.0001 6.55229 11.5523 7 11.0001 7L8.00006 7Z"
                      fill="var(--md_gray)"
                    />
                    <path
                      d="M12.757 11.2428C12.3664 10.8522 12.3664 10.2191 12.757 9.82854L15.4644 7.12109H14.879C14.3267 7.12109 13.879 6.67338 13.879 6.12109C13.879 5.56881 14.3267 5.12109 14.879 5.12109L17.879 5.12109C18.4313 5.12109 18.879 5.56881 18.879 6.12109V9.12109C18.879 9.67338 18.4313 10.1211 17.879 10.1211C17.3267 10.1211 16.879 9.67338 16.879 9.12109V8.53497L14.1712 11.2428C13.7807 11.6333 13.1475 11.6333 12.757 11.2428Z"
                      fill="var(--md_gray)"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          {currentUser && currentUser.loggedIn ? (
            <div className="flex">
              {currentUser && currentUser.admin && (
                <>
                  <Link className="btn btn-secondary" to="/upload/pathways">
                    Upload Pathways
                  </Link>
                  <div className="spacer-8" />
                </>
              )}
              {currentUser && currentUser.admin && (
                <>
                  <Link className="btn btn-secondary" to="/upload/smo">
                    Upload SMO
                  </Link>
                  <div className="spacer-8" />
                </>
              )}
              <button className="btn btn-secondary" onClick={handleSignOut}>
                Sign out
              </button>
            </div>
          ) : (
            <button className="btn btn-primary" onClick={handleSignInRequest}>
              Sign in
            </button>
          )}
        </header>

        <div className="spacer-64" />

        <div className="pos-rel">
          <img className="dots left show-lg" src={DotsVertGray} alt="" />
          <img className="dots right bottom show-lg" src={DotsVertGray} alt="" style={{ bottom: 64 }} />

          <div className="container">
            <div className="row align-center start-xs">
              <div className="col-lg-8 col-xs-12">
                <h1 className="jumbo">
                  International
                  <br />
                  Standards
                </h1>
              </div>

              <div className="col-lg-4 col-xs-12">
                <div className="spacer-48 hide-lg"></div>
                <div className="flex space-between align-center start-xs">
                  <p className="lead-md">
                    Watch a quick tour of
                    <br />
                    using the standards
                  </p>
                  <div className="spacer-48" />
                  <button
                    onClick={() => {
                      setShowVideo(true);

                      setTimeout(() => {
                        const player = document.querySelector(".video-player");
                        player.play();
                      }, 800);
                    }}
                    className="btn btn-secondary btn-circle btn-circle-lg"
                    data-tip="Watch video"
                    aria-label="Watch video"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.5266 12.3653L8 7.3218L8 16.8484L17.5266 12.3653ZM8.93578 5.55423C7.60377 4.84905 6 5.81465 6 7.3218V16.8484C6 18.3145 7.5251 19.2823 8.85159 18.6581L18.3782 14.1749C19.8699 13.473 19.9194 11.3691 18.4624 10.5977L8.93578 5.55423Z"
                        fill="var(--md_gray)"
                      />
                    </svg>
                  </button>
                </div>
                <div className="spacer-64" />
              </div>
            </div>

            <div className="spacer-8" />

            <div className="row align-bottom">
              <div className="col-lg-8 col-xs-12 flex column-xxs row-xs" style={{ marginBottom: "64px" }}>
                <ul className="standards-list">
                  <li>
                    <button
                      className="btn btn-primary btn-block"
                      data-path="/standards/pathways/2021"
                      onClick={(e) => handleNavigateToBoard(e)}
                      aria-label="Pathways"
                    >
                      <div>
                        Pathways
                        <p className="xs">eHandbook</p>
                      </div>
                      <img src={ArrowRightIcon} alt="" />
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-primary btn-block"
                      data-path="/standards/smo/2021"
                      onClick={(e) => handleNavigateToBoard(e)}
                      aria-label="SMO"
                    >
                      <div>
                        SMO
                        <p className="xs">eHandbook</p>
                      </div>
                      <img src={ArrowRightIcon} alt="" />
                    </button>
                  </li>
                </ul>

                <div className="spacer-64 show-xs hide-md"></div>

                <div>
                  <div className="spacer-20" />
                  <div className="divider-lg" />
                  <div className="spacer-24" />
                  <p className="lead">
                    The work of the international, independent standard-setting boards supports the global
                    economy and financial markets by producing high-quality, global standards for audit and
                    assurance, professional ethics, public sector financial reporting, and professional
                    skills and competencies.
                  </p>
                </div>
                <div className="spacer-96 hide-xs show-md" />
              </div>

              <div className="col-lg-4 col-xs-12">
                <img className="hero-img hide-xs show-lg" src={OfficeImg} alt="Office" />
                <img className="hero-img hide-lg show-xs" src={OfficeMobileImg} alt="Office" />
              </div>
            </div>
          </div>
        </div>

        <div className="spacer-48 " />

        <footer className="footer" role="contentinfo">
          <div className="footer-inner flex flex-1 space-between align-bottom">
            <ul className="flex align-bottom">
              <li>
                <span className="copyright">&copy;</span> {new Date().getFullYear()}
              </li>
              <li>All rights reserved</li>
              <li>
                Powered by{" "}
                <a
                  className="ifac-logo"
                  href="https://www.ifac.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-for="footer-tips"
                  data-tip="Go to www.ifac.org"
                >
                  <img src={IFACLogo} alt="IFAC Logo" />
                </a>
              </li>
            </ul>

            <ul className="flex">
              <li>
                <a href="https://www.ifac.org/terms-use" target="_blank">Terms</a>
              </li>
              <li>
                <a href="https://www.ifac.org/privacy-policy" target="_blank">Privacy</a>
              </li>
              <li>
                <a href="https://www.ifac.org/contact" target="_blank">Contact</a>
              </li>
            </ul>
          </div>
        </footer>

        <ReactTooltip className="tooltip-sm" place="bottom" disable={width <= 992} />
        <ReactTooltip className="tooltip-sm" id="footer-tips" place="right" disable={width <= 992} />
        <ToastContainer transition={Slide} className="toast-container" duration={6500} />
      </div>
    </RouteTransitionWrapper>
  );
};

export default Home;
