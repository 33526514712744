import React, { useState, useEffect, useRef, useCallback } from "react";
import FloatingPanel from "../components/FloatingPanel";
import favicon1 from "../assets/eis-logo-1.ico";
import favicon2 from "../assets/eis-logo-2.ico";
import favicon3 from "../assets/eis-logo-3.ico";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStoreState, useStoreActions } from "easy-peasy";
import { RouteTransitionWrapper, TOC, SidePanel, MainContent, Modal } from "../components";
import { fs } from "../firebase";

const Standards = () => {
  const location = useLocation();
  const history = useHistory();
  const { board: boardFromParam, version: versionFromParam } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const currentUser = useStoreState((state) => state.user);
  const currentBoard = useStoreState((state) => state.session.currentBoard);
  const currentSectionId = useStoreState((state) => state.session.currentSectionId);
  const currentVersion = useStoreState((state) => state.session.currentVersion);

  const setCurrentBoard = useStoreActions((actions) => actions.setCurrentBoard);
  const setCurrentSectionId = useStoreActions((actions) => actions.setCurrentSectionId);
  const setCurrentStandardInStore = useStoreActions((actions) => actions.setCurrentStandard);
  const setSectionNamesInStore = useStoreActions((actions) => actions.setSectionNames);
  const setCurrentVersion = useStoreActions((actions) => actions.setCurrentVersion);

  const [currentSection, setCurrentSection] = useState(null);
  const [currentStandard, setCurrentStandard] = useState(null);
  const [sectionNames, setSectionNames] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [boardVersions, setBoardVersions] = useState(null);
  const [boardResources, setBoardResources] = useState(null);

  const [showVideo, setShowVideo] = useState(false);

  const TOCRef = useRef(null);
  const mainContentRef = useRef(null);
  const contentScrollRef = useRef(null);
  const headroomRef = useRef(null);

  const [showMobileTOC, setShowMobileTOC] = useState(false);
  const [showMobilePanel, setShowMobilePanel] = useState(false);

  const [showFloatingPanel, setShowFloatingPanel] = useState(false);
  const [scrollSpyItems, setScrollSpyItems] = useState([]);
  const [footnotes, setFootnotes] = useState([]);
  const [references, setReferences] = useState([]);
  const [refArray, setRefArray] = useState([]);
  const [showNotAvailableMsg, setShowNotAvailableMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [panelTitle, setPanelTitle] = useState(null);

  const searchTermRef = useRef('');

  const toggleMobileTOC = useCallback(() => {
    setShowMobileTOC(!showMobileTOC);
    setShowMobilePanel(false);
  }, [showMobileTOC, setShowMobileTOC]);

  const toggleMobilePanel = useCallback(() => {
    setShowMobilePanel(!showMobilePanel);
    setShowMobileTOC(false);
  }, [showMobilePanel, setShowMobilePanel]);

  const getStandard = async (board, version) => {

    const bearerToken = process.env.REACT_APP_GCP_BEARER_TOKEN;
    let boardName = board;
    let userIdentifier = currentUser.uid;
    const payloadForGetDownloadURL = {
      boardName,
      version,
      userIdentifier
    };



    let docUrl;
    function getUrlTaskPromise() {
      return new Promise(function (resolve, reject) {
        const docStorageRef = fs.ref(
          board + "/" + version + "/" + "standards-split.json"
        );
        docStorageRef.getDownloadURL().then((url) => {
          docUrl = url;
          resolve();
        })
        .catch(() => {
          reject();
          const errormsg = "We are experiencing an issue loading the handbook.";
          console.log(errormsg);
          setErrorMsg(errormsg);
          setShowNotAvailableMsg(true);
          setTimeout(() => {
            history.push(`/`);
          }, 2000);
        });
      })
    };

    getUrlTaskPromise().then(() => {
      console.log(docUrl);

      fetch(docUrl, {
        method: "get",
        headers: {
          accept: "application/json",
        },
        mode: "cors",
      }).then((res) => res.json())
        .then((res) => {
          if (res === "undefined") return;
          const standard = res.result[0];
          setCurrentStandard(standard);
          setCurrentSection(standard.handbook[0][0].content);
          setCurrentStandardInStore(standard);
          setCurrentSectionId(standard.handbook[0][0].id);
          setSectionNames(getSectionNames(standard.toc[0]));
          setSectionNamesInStore(getSectionNamesForStore(standard.toc[0]));
          setEffectiveDate(standard.effectiveDate ? standard.effectiveDate : null);
          setIsLoading(false);
          updateFavicon(board);
          updateTitle(board);

          handleUrlParamsCheck(standard);
        })
        .catch(() => {
          const errormsg = "We are experiencing an issue loading the handbook.";
          console.log(errormsg);
          setErrorMsg(errormsg);
          setShowNotAvailableMsg(true);
        });
    });
  }


  const getBoardVersions = async () => {
    const url = process.env.REACT_APP_GET_DOCUMENT_VERSION_URL;

    await fetch(url, {
      method: "get",
      headers: {
        accept: "application/json",
        authorization: "Bearer " + process.env.REACT_APP_GCP_BEARER_TOKEN,
        "content-type": "application/json",
      },
      mode: "cors",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === "undefined") return;
        const versions = res;
        setBoardVersions(versions);
      });
  };

  const getBoardResources = async (boardValue) => {
    let resourceUrl;
    let resources = {"general":[], "specific":{},};
    function getUrlTaskPromise() {
      return new Promise(function (resolve, reject) {
        const docStorageRef = fs.ref(
          boardValue + "/" + "resources.json"
        );
        docStorageRef.getDownloadURL().then((url) => {
          resourceUrl = url;
          resolve();
        })
          .catch((error) => {
            reject();
            console.log(error);
            setBoardResources(resources);
          });
      });
    };
    
        getUrlTaskPromise().then(() => {
          console.log(resourceUrl);
    
          fetch(resourceUrl, {
            method: "get",
            headers: {
              accept: "application/json",
            },
            mode: "cors",
          }).then((res) => {
            if(res.ok) {
              return res.json();
            }
            else{
              return resources;
            }
    
          })
          .then((res) => {
            if (res !== "undefined") {
               resources = res;
            }
            setBoardResources(resources);
          })
          .catch(() => { setBoardResources(resources);})
          ;
        });
  };

  const updateFavicon = (board) => {
    const favicon = document.querySelector("#favicon");

    if (board === "iaasb") {
      favicon.href = favicon1;
    } else if (board === "iesba") {
      favicon.href = favicon2;
    } else if (board === "ipsasb") {
      favicon.href = favicon3;
    }
  };

  const updateTitle = (board) => {
    //const title = document.title;

    if(board === "pathways"){
      document.title = "IFAC";
    } else if(board === "smo"){
      document.title = "IFAC SMO";
    }
  }

  const getUrlVars = () => {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      vars[key] = value;
    });
    return vars;
  };

  const handleUrlParamsCheck = (standard) => {
    let hasSection = false;
    let hasRef = false;
    let hash;
    let section;
    let searchTerm;
    let hasSearch;

    if (window.location.hash) {
      hasRef = true;
      hash = window.location.hash;
    }

    if (getUrlVars()["section"]) {
      hasSection = true;
      section = getUrlVars()["section"].split("#")[0];
    }

    if (getUrlVars()["search"]) {
      hasSearch = true;
      searchTerm = getUrlVars()["search"];
      searchTerm = searchTerm.toLowerCase().split('%20').join(' ');
      searchTermRef.current = searchTerm;
    }

    if (hasSection && hasRef) {
      getCurrentSection(standard, section);
      scrollTo(hash, hasSearch, searchTerm);
    } else if (hasSection) {
      getCurrentSection(standard, section);
    } else if (hasRef) {
      scrollTo(hash, hasSearch, searchTerm);
    }
  };

  const scrollTo = (hash, hasSearch, searchTerm) => {
    const ref = document.querySelector(hash);
    if (!ref) return;
    const refRect = ref.getBoundingClientRect();
    mainContentRef.current.scrollTop = refRect.top;
    if(hasSearch){
      let elements = document.querySelectorAll('.standard-content p,.standard-content li');
      for (let el of elements) {
        if(el.innerHTML.toLowerCase().includes(searchTerm)){
          replaceSearchString(el, searchTerm, el.innerHTML.toLowerCase());
        }
      }
    }
  };

  const replaceSearchString = (el, searchTerm, searchString) => {
    if(searchString.indexOf(searchTerm) > 0){
      const strToReplace = el.innerHTML.substr(searchString.indexOf(searchTerm), searchTerm.length);
      el.innerHTML = el.innerHTML.split(strToReplace).join('<span style="background-color: yellow !important;">' + strToReplace + '</span>');
      const newSearchString = searchString.substring(searchString.indexOf(searchTerm), searchString.length - 1);
      replaceSearchString(el, searchTerm, newSearchString);
    }
  }

  useEffect(() => {
    setIsLoading(true);

    boardFromParam && setCurrentBoard(boardFromParam);
    versionFromParam && setCurrentVersion(versionFromParam);
    boardFromParam && versionFromParam && getStandard(boardFromParam, versionFromParam);
    //getBoardVersions();
    getBoardResources(boardFromParam);
  }, [boardFromParam, versionFromParam]);

  useEffect(() => {
    TOCRef.current && handleAccordionToggles(TOCRef.current);

    currentStandard !== null && currentStandard.toc && setScrollSpyItems(getScrollSpyArray(currentStandard.toc));
  }, [currentStandard]);

  useEffect(() => {
    currentStandard !== null && initFootnotes();
    currentStandard !== null && initReferences();
    currentStandard !== null && initPopovers();
    currentStandard !== null && initShareLinks();
  }, []);

  useEffect(() => {
    currentStandard !== null && initFootnotes();
    currentStandard !== null && initReferences();
    currentStandard !== null && initPopovers();
    currentStandard !== null && initShareLinks();
  }, [currentSectionId]);

  const getCurrentSection = (standard = currentStandard, id) => {
    const section = standard.handbook[0].filter((section) => section.id === id);
    if (!section) return;
    setCurrentSection(section[0]?.content);
    setCurrentSectionId(id);

    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("section", id);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  const getSectionNames = (toc) => {
    let sections = [];

    toc.map((el) => {
      const section = { containerId: el.containerId, key: el.key, name: el.name };
      return sections.push(section);
    });

    return sections;
  };

  const getSectionNamesForStore = (toc) => {
    let sections = [];
    for (const el of toc) {
      const section = { containerId: el.containerId, key: el.key, name: el.name };
      sections.push(section);
      for (const child of el.children) {
        const childSection = { containerId: child.containerId, key: child.key, name: child.name };
        sections.push(childSection);
      }
    }
    return sections;
  };

  // const initFootnotes = () => {
  //   setTimeout(() => {
  //     const footnoteLinks = contentScrollRef.current && contentScrollRef.current.querySelectorAll('a[href*="ftnref"]');
  //     if (!footnoteLinks) return;
  //     let refArr = [];
  //     let footnotesArr = [];

  //     footnoteLinks.forEach((link, i) => {
  //       const href = link.href;
  //       const refNumber = href.substring(href.indexOf("ftnref") + 1);
  //       refArr.push(refNumber);
  //     });

  //     currentStandard !== null &&
  //       currentStandard.footnotes &&
  //       currentStandard.footnotes.forEach((footnote, i) => {
  //         refArr.forEach((ref) => {
  //           if (ref === i) {
  //             footnotesArr.push(footnote);
  //           }
  //         });
  //       });

  //     setFootnotes(footnotesArr);
  //   }, 500);
  // };

  const initReferences = () => {
    setTimeout(() => {
      const referenceLinks = contentScrollRef.current && contentScrollRef.current.querySelectorAll("[data-ref-id]");
      if (!referenceLinks) return;

      referenceLinks.forEach((reference) => {
        const id = reference.attributes["data-ref-id"].nodeValue;
        reference.addEventListener("click", (e) => handleToggleReferences(e, id));
      });
    }, 500);
  };

  const initFootnotes = () => {
    setTimeout(() => {
      const footnoteLinks = contentScrollRef.current && contentScrollRef.current.querySelectorAll("[data-ftn-ref]");
      if (!footnoteLinks) return;

      footnoteLinks.forEach((ftn) => {
        const id = ftn.attributes["data-ftn-ref"].nodeValue;
        ftn.addEventListener("click", (e) => handleToggleFootNotes(e, id));
      });
    }, 500);
  };

  const handleToggleFootNotes = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    let references = [];
    let ref = currentStandard.footnotes[0].find((ref) => ref.id === id.trim());
    if (typeof ref === "undefined") {
      ref = { id: id, content: "Reference not available." };
    }
    else {
      ref = { id: ref.id.replace("_edn", ""), content: ref.value };
    }
    references.push(ref);
    setReferences(references);

    if (references.length === 0) {
      return;
    } else {
      setPanelTitle("Footnote");
      toggleFloatingPanel();
    }
  };

  const range = (start, end) => {
    let ans = [];

    for (let i = start; i <= end; i++) {
      ans.push(i.toString());
    }

    return ans;
  };

  const handleToggleReferences = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    let references = [];
    let refArray = [];
    let multipleIds = "";
    let isMultipleIds = false;
    if((id.includes("to") && id.includes("and"))){
      multipleIds =  id.split("and");
      isMultipleIds = true; 
    }
    if(id.includes(",") && id.includes("and")){
      multipleIds =  id.split("and").join("-").split(",").join("-").split("-");
      isMultipleIds = true;
    }
    if(id.includes(",")){
      multipleIds =  id.split(",");
      isMultipleIds = true;
    }
    else{
      multipleIds = id;
      isMultipleIds = false;
    }
    if(isMultipleIds){
      for (let i = 0; i < multipleIds.length; i++) {
        id = multipleIds[i].trim();
        const charStart = id.charAt(0);
        if(charStart === "(" || (charStart === "A" && currentBoard === "iesba")){
          id = multipleIds[i-1].split(charStart)[0].trim() + (charStart ==="A"?" ":"") + id;
        }

         createReferences(id,refArray,references); 
      }
    }
    else{
      id = multipleIds;
      createReferences(id,refArray,references); 
    }

    setReferences(references);
    if (references.length === 0) {
      return;
    } else {
      if (currentBoard.toUpperCase() === "IPSASB") {
        setPanelTitle("Reference Material");
      }
      else {
        setPanelTitle("Application Material");
      }

      toggleFloatingPanel();
    }
  };

  const createReferences = (id, refArray, references) => {
    if (id.includes("–") || id.includes("to")) {
  
      let prefixLabel = id.charAt(0); //for ex. R112 A1-R112 A7 or R112 A1 to R112 A7 or 1-7 or 1 to 7 or R11.1 to R11.7
      let cleanedIds = id;
      let isNanRange = false;
      if (isNaN(prefixLabel)) {
        cleanedIds = id.replaceAll(prefixLabel, ""); // 112 A1-112 A7 or 11.1 to 11.7
      } else {
        prefixLabel = "";
      }

      cleanedIds = cleanedIds.replace("to", "-");
      cleanedIds = cleanedIds.replace("–", "-");
      const rangeStartEnd = cleanedIds.split("-"); // 112 A1, 112 A7 or 1,7 or 11.1,11.7

      let start = rangeStartEnd[0].trim();
      let prefix1 = "";
      let prefix2 = "";
      if (isNaN(start)) {
        if (prefixLabel.toUpperCase() === "B") {  // BC5.5, BC5.15
          const char = start.charAt(0);
          if (char.toUpperCase() === "C") {
            prefix1 = char;
            const bcid = start.toUpperCase().replaceAll("C", "");
            if (!isNaN(bcid)) {
              if (bcid.includes(".")) {
                const startArray = bcid
                  .trim()
                  .split(".")
                  .filter((i) => i);
                prefix2 = startArray[0] + ".";
                start = startArray[1];
              }
            }
          }
        }
        else {
          const smallParenthesisStartIndex = start.indexOf("(");
          if(smallParenthesisStartIndex > 0){
            start = start.substr(0, smallParenthesisStartIndex) + " " + start.substr(smallParenthesisStartIndex);
          }
          const startArray = start
            .trim()
            .split(" ")
            .filter((i) => i);
          prefix1 = startArray[0];
          if (isNaN(startArray[1])) {
            if(startArray[1].startsWith("(")){
              prefix2 = "";
              start = startArray[1];
              isNanRange = true;
            }
            else{
              prefix2 = startArray[1].charAt(0);
              start = startArray[1].replace(prefix2, "");
            }
          }
        }
      } else {
        if (start.includes(".")) {
          const startArray = start
            .trim()
            .split(".")
            .filter((i) => i);
          prefix1 = startArray[0] + ".";
          start = startArray[1];
        }
      }

      let end = rangeStartEnd[1].trim();
      if(end.startsWith("(")){
        isNanRange = true;
      }
      else{
        if (isNaN(end.charAt(0))) {
          end = end.replace(end.charAt(0), "");
        }
        if (isNaN(end)) {
          const endArray = end
            .trim()
            .split(" ")
            .filter((i) => i);
          if (isNaN(endArray[1])) {
            const endPrefix2 = endArray[1].charAt(0);
            end = endArray[1].replace(endPrefix2, "");
          }
        } else {
          if (end.includes(".")) {
            const endArray = end
              .trim()
              .split(".")
              .filter((i) => i);
            end = endArray[1];
          }
        }
      }



      
      if(isNanRange){
        refArray.push(prefixLabel + prefix1 + start);
        refArray.push(prefixLabel + prefix1 + end);
      }
      else{

        const rangeArray = range(parseInt(start), parseInt(end));

        for (let i = 0; i < rangeArray.length; i++) {
          const el = rangeArray[i];
          if (prefix2.length > 0) {
            if (prefixLabel.toUpperCase() === "B") {
              refArray.push(prefixLabel + prefix1 + prefix2 + el);
            }
            else {
              refArray.push(prefixLabel + prefix1 + " " + prefix2 + el);
            }
  
          } else {
            refArray.push(prefixLabel + prefix1 + el);
          }
        }
      }
      
      populateReferences(refArray, references);

    } else if (id.includes("and") || id.includes(",") || id.includes("or")) {
      const cleanedIds = id.replace("and", "-").replace(",", "-").replace("or", "-");
      const startEnd = cleanedIds.split("-");
      let end = startEnd[1].trim();
      if(end.startsWith("(")){
        end = startEnd[0].substring(0, startEnd[0].indexOf("(")) + end;
      }
      refArray.push(startEnd[0].trim());
      refArray.push(end);

      populateReferences(refArray, references);

      //setReferences(references);
    } else {
      let ref = currentStandard.references[0].find((ref) => ref.id.trim() === id.trim() && ref.containerId === currentSectionId);
      if (typeof ref === "undefined") {
        ref = currentStandard.references[0].find((ref) => ref.id.trim() === id.trim());
      }
      if (typeof ref === "undefined") {
        ref = { id: id, content: "Reference not available." };
      }
      references.push(ref);
  } 
}

  const populateReferences = (refArray, references) => {
    refArray.map((id) => {
      let ref = currentStandard.references[0].find((ref) => ref.id.trim() === id.trim() && ref.containerId === currentSectionId);
      if (typeof ref === "undefined") {
        ref = currentStandard.references[0].find((ref) => ref.id.trim() === id.trim());
      }
      if (typeof ref === "undefined") {
        ref = { id: id, content: "Reference not available." };
      }
      references.push(ref);
    });
  }

  const initPopovers = () => {
    setTimeout(() => {
      const popoverLinks = contentScrollRef.current && contentScrollRef.current.querySelectorAll(".popover-hover");
      if (!popoverLinks) return;

      popoverLinks.forEach((popover) => {
        popover.addEventListener("click", handleTogglePopover);
      });
    }, 500);
  };

  const handleTogglePopover = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const popoverHover = e.target;
    const definitions = currentStandard && currentStandard.glossary && currentStandard.glossary[0];
    const contentArray = definitions.find((item) => item.term.toLowerCase() === popoverHover.innerText.toLowerCase());
    const popoverContent = e.target.previousSibling;
    popoverContent.innerText = contentArray && contentArray.definition.join('\r\n');

    const linkWidth = e.target.offsetWidth;
    let marginLeft = `${linkWidth / 2}px !important`;
    const xdistance = e.x;
    if (xdistance > 750) {
      marginLeft = `${linkWidth / 2 - 50}px !important`;
    }

    if (xdistance < 550) {
      marginLeft = `${linkWidth / 2 + 50}px !important`;
    }
    popoverContent.setAttribute("style", `margin-left: ${marginLeft}`);

    if (popoverContent.classList.contains("shown")) popoverContent.classList.remove("shown");
    else popoverContent.classList.add("shown");

    popoverContent.addEventListener("click", (e) => e.stopPropagation());

    hideAllPopovers(popoverContent);
  };

  const hideAllPopovers = (excludePopover) => {
    if (typeof excludePopover === null) return;

    const popoverContents =
      contentScrollRef.current && contentScrollRef.current.querySelectorAll(".popover .popover-content");
    if (!popoverContents) return;

    popoverContents.forEach((content) => {
      if (content === excludePopover) return;
      if (content.classList.contains("shown")) content.classList.remove("shown");
    });
  };

  const initShareLinks = () => {
    setTimeout(() => {
      const shareLinks = contentScrollRef.current && contentScrollRef.current.querySelectorAll("[data-share-btn]");
      if (!shareLinks) return;

      shareLinks.forEach((link) => {
        link.addEventListener("click", handleShareLink);
      });
    }, 500);
  };

  const handleShareLink = (e) => {
    const parent = e.target.closest(".paragraph-wrapper");
    const anchor = parent.querySelector("[data-ref]").getAttribute("id");
    const heading = parent.querySelector("[data-ref]").innerText;;
    const currentUrl = window.location.href.split("/")[2];
    const generatedLink = currentUrl + location.pathname + "?section=" + currentSectionId + "#" + anchor;
    // let  standardName= "";
    // let sectionName = "";
    // let paragraphWithoutLastDot = paragraph;
    // const sections = getSectionNamesForStore(currentStandard.toc[0]);
    
    // if(currentBoard === 'iesba'){
    //   let hash = "#";
    //   if (window.location.hash) {
    //     hash = window.location.hash;
    //   }
    //   sectionName = sections.filter((e) => ((e.containerId === currentSectionId) && (e.key === hash) && (e.name.startsWith('SECTION'))))[0]?.name;
    //   if(sectionName)
    //   standardName = sectionName.substring(0, sectionName.indexOf("-"));
    // }
    // if(currentBoard === 'iaasb'){
    //   sectionName = sections.filter((e) => ((e.key === "#" + currentSectionId) && ((e.name.startsWith('ISA')) || 
    //   (e.name.startsWith('ISQC')) || (e.name.startsWith('IAPN')))))[0]?.name;
    //   if(sectionName)
    //   standardName = sectionName.substring(0, sectionName.indexOf(","));
    // }
    // if(currentBoard === 'ipsasb'){
    //   sectionName = sections.filter((e) => ((e.key === "#" + currentSectionId) && (e.name.startsWith('IPSAS'))))[0]?.name;
    //   if(sectionName)
    //   standardName = sectionName.substring(0, sectionName.indexOf("—"));
    // }

    // const lastChar = paragraph.charAt(paragraph.length-1);
    // if(lastChar === "."){ paragraphWithoutLastDot = paragraph.substring(0, paragraph.length - 1);}

    const subject = "Link to " + currentBoard.toUpperCase() + " \"" + heading + "\"";
    const body = "I'm using " + currentBoard.toUpperCase() + " \"" + heading + "\""
      + ". Click the link below to use it as well https://"
      + generatedLink;
    const mailTo = "mailto:?subject=" + subject + "&body=" + body;

    window.open(mailTo, "_blank");
  };

  const handleAccordionToggles = (el) => {
    const accordions = el.querySelectorAll(".accordion-group");
  };

  useEffect(() => {
    currentStandard !== null && initFootnotes();
    currentStandard !== null && initReferences();
    currentStandard !== null && initPopovers();
    currentStandard !== null && initShareLinks();

    window.addEventListener("click", (e) => {
      e.stopPropagation();
      hideAllPopovers(null);
    });
  }, [currentStandard]);

  const getScrollSpyArray = (data) => {
    let collection = [];
    const getKey = (el) => Object.values(el).join("").replace(/#/g, "");

    for (let i = 0; i < data.length; i++) {
      const item = data[0][i];
      const value = getKey(item.key);

      if (item.children.length === 0) {
        collection.push(value);
      } else {
        for (let i = 0; i < item.children.length; i++) {
          const child = item.children[i];
          const value = getKey(child.key);
          collection.push(value);
        }
      }
    }

    return collection;
  };

  const handleBoardNavUpdate = (e) => {
    const boardValue = e.target.value;
    setCurrentBoard(boardValue);
    let versionValue = currentVersion;
    if (!boardVersions[boardValue].includes(currentVersion)) {
      const errormsg = "This version has not been published yet, displaying the most recent version.";
      console.log(errormsg);
      setErrorMsg(errormsg);
      setShowNotAvailableMsg(true);
      versionValue = boardVersions[boardValue][boardVersions[boardValue].length - 1];
      setCurrentVersion(versionValue);
    }
    setCurrentStandard(boardValue, versionValue);
    history.push(`/standards/${boardValue}/${versionValue}`);
    initFootnotes();
    initReferences();
    initPopovers();
    mainContentRef.current.scrollTop = 0;
  };

  const handleVersionNavUpdate = (e) => {
    const versionValue = e.target.value;
    setCurrentVersion(versionValue);
    setCurrentStandard(currentBoard, versionValue);
    history.push(`/standards/${currentBoard}/${versionValue}`);
    initFootnotes();
    initReferences();
    initPopovers();
    mainContentRef.current.scrollTop = 0;
  };

  const toggleFloatingPanel = useCallback(() => setShowFloatingPanel(!showFloatingPanel), [
    showFloatingPanel,
    setShowFloatingPanel,
  ]);

  return (
    <RouteTransitionWrapper>
      {isLoading && (
        <div className="loader-full-bg loader-full-height">
          <div className="loader loader-lg" />
        </div>
      )}

      <Modal
        title="This handbook version is not available"
        show={showNotAvailableMsg}
        close={() => setShowNotAvailableMsg(false)}
        showCloseBtn={false}
        backdropDismiss={false}
      >
        <p className="sm">{errorMsg}</p>

        <div className="spacer-12" />

        <button
          className="btn btn-blue"
          onClick={() => {
            setShowNotAvailableMsg(false);
          }}
        >
          OK
        </button>
      </Modal>

      <Modal
        show={showVideo}
        close={() => setShowVideo(false)}
        showCloseBtn={false}
        backdropDismiss={true}
        isVideo={true}
      >
        <video className="video-player" width="100%" controls>
          <source className="video-player-src" src="" />
          Your browser does not support HTML5 video.
        </video>
      </Modal>

      <div className="standards-view">
        <FloatingPanel references={references} title={panelTitle} show={showFloatingPanel} close={() => setShowFloatingPanel(false)} />

        <TOC
          isLoading={isLoading}
          currentBoard={currentBoard}
          currentVersion={currentVersion}
          currentStandard={currentStandard}
          boardVersions={boardVersions}
          TOCRef={TOCRef}
          scrollSpyItems={scrollSpyItems}
          handleBoardNavUpdate={handleBoardNavUpdate}
          handleVersionNavUpdate={handleVersionNavUpdate}
          showMobileTOC={showMobileTOC}
          getCurrentSection={getCurrentSection}
          headroomRef={headroomRef}
          currentSectionId={currentSectionId}
        />

        <MainContent
          isLoading={isLoading}
          currentStandard={currentStandard}
          mainContentRef={mainContentRef}
          contentScrollRef={contentScrollRef}
          headroomRef={headroomRef}
          currentBoard={currentBoard}
          currentVersion={currentVersion}
          currentSection={currentSection}
          footnotes={footnotes}
          sectionNames={sectionNames}
          effectiveDate={effectiveDate}
          definitions={currentStandard && currentStandard.glossary && currentStandard.glossary[0]}
          showMobileTOC={showMobileTOC}
          setShowMobileTOC={setShowMobileTOC}
          toggleMobileTOC={toggleMobileTOC}
          showMobilePanel={showMobilePanel}
          setShowMobilePanel={setShowMobilePanel}
          toggleMobilePanel={toggleMobilePanel}
          searchTermRef={searchTermRef.current}
        />

        <SidePanel
          isLoading={isLoading}
          definitions={currentStandard && currentStandard.glossary && currentStandard.glossary[0]}
          resources={currentStandard && boardResources}
          setBoardResources={setBoardResources}
          getBoardResources={getBoardResources}
          showMobilePanel={showMobilePanel}
          setShowVideo={setShowVideo}
        />
      </div>

      <ToastContainer transition={Slide} type="info" className="toast-container" />
    </RouteTransitionWrapper>
  );
};

export default Standards;
